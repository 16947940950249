import { render, staticRenderFns } from "./jurisdiction.vue?vue&type=template&id=f657c850&scoped=true&lang=true&"
import script from "./jurisdiction.vue?vue&type=script&lang=js&"
export * from "./jurisdiction.vue?vue&type=script&lang=js&"
import style0 from "./jurisdiction.vue?vue&type=style&index=0&id=f657c850&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.10.0@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f657c850",
  null
  
)

export default component.exports